import Image from 'next/image';
import masterCardColor from '@/assets/images/masterCardColor.png';
import visaColor from '@/assets/images/visaColor.png';
import googlePayColor from '@/assets/images/googlePayColor.svg';
import instantEFT from '@/assets/images/instantEFT.png';
import samsungpay from '@/assets/images/samsungpay.png';
import zapper from '@/assets/images/zapper.png';

interface CartPaymentOptionsProps {
  gateway: string;
}
export default function CartPaymentOptions({
  gateway,
}: CartPaymentOptionsProps) {
  const isIkhokha = gateway === 'ikhokha';
  const paymentOptionsList = [
    {
      title: 'Google Pay',
      enabled: isIkhokha,
      images: [
        {
          imageUrl: googlePayColor,
          width: 766,
          height: 435,
          name: 'Google Pay',
        },
      ],
    },
    {
      title: 'Credit & Debit Card',
      enabled: true,
      images: [
        {
          imageUrl: masterCardColor,
          width: 51,
          height: 42,
          name: 'MasterCard',
        },
        {
          imageUrl: visaColor,
          width: 84,
          height: 30,
          name: 'Visa',
        },
      ],
    },
    {
      title: 'Instant EFT',
      enabled: true,
      images: [
        {
          imageUrl: instantEFT,
          width: 800,
          height: 93,
          name: 'Instant EFT',
        },
      ],
    },
    {
      title: 'Samsung Pay',
      enabled: !isIkhokha,
      images: [
        {
          imageUrl: samsungpay,
          width: 840,
          height: 225,
          name: 'Samsung Pay',
        },
      ],
    },
    {
      title: 'Zapper',
      enabled: !isIkhokha,
      images: [
        {
          imageUrl: zapper,
          width: 1000,
          height: 207,
          name: 'Zapper',
        },
      ],
    },
  ];
  return (
    <div className='space-y-4 border-t border-gray-200 py-6'>
      <h3 className='font-bold'>Available payment options:</h3>
      {paymentOptionsList
        .filter((option) => option.enabled)
        .map((option, index) => (
          <div key={index} className='flex items-center justify-between'>
            <div className='flex-shrink-0 pr-2'>{option.title}</div>
            <div className='flex gap-2'>
              {option.images.map((image, imageIndex) => (
                <Image
                  key={`${index}-${imageIndex}`}
                  className='h-6 w-auto'
                  src={image.imageUrl}
                  width={image.width}
                  height={image.height}
                  alt={image.name}
                />
              ))}
            </div>
          </div>
        ))}
    </div>
  );
}
