import { useAtomValue } from 'jotai';
import { cartAtom, cartItemsQuantityAtom } from '@/store/cartStore';
import { formatPrice } from '@/sanity/utils/shopify/priceHelpers';

// import { cartItemsQuantityAtom } from '@/scripts/store/cartStore';
export default function CartCostBreakdown({
  displayType,
}: {
  displayType: string;
}) {
  const cart = useAtomValue(cartAtom);
  const cartItemsQuantity = useAtomValue(cartItemsQuantityAtom);
  return (
    <>
      {cart && cart.totalPrice && (
        <dl className='space-y-2 py-4'>
          {cart.discountPrice > 0 && (
            <div className='flex items-center justify-between rounded-md bg-teal-50 p-2'>
              <dt>
                Discount{' '}
                <span className='ml-2 inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-teal ring-1 ring-inset ring-teal/10'>
                  {cart.discountCode}
                </span>
              </dt>
              <dd>{formatPrice(-cart.discountPrice)}</dd>
            </div>
          )}
          <div className='flex items-center justify-between'>
            <dt>Subtotal</dt>
            <dd>{formatPrice(cart.subtotalPrice)}</dd>
          </div>
          <div className='flex items-center justify-between'>
            <dt>Delivery</dt>

            <dd>
              {displayType === 'purchase-journey'
                ? cart.deliveryPrice > 0
                  ? formatPrice(cart.deliveryPrice)
                  : 'FREE'
                : cart.deliveryPrice > 0
                  ? 'Calculated at checkout'
                  : 'FREE'}
            </dd>
          </div>
          <div className='flex items-center justify-between'>
            <dt>VAT</dt>
            <dd>{formatPrice(cart.totalTax)}</dd>
          </div>

          <div className='flex items-center justify-between pt-2'>
            <dt className='text-lg font-bold'>
              <>
                Total:{' '}
                <>
                  ({cartItemsQuantity}{' '}
                  {cartItemsQuantity > 1 ? 'items' : 'item'})
                </>
              </>
            </dt>
            <dd className='text-lg font-bold' data-test-qa='pj-total'>
              {formatPrice(cart.totalPrice)}
            </dd>
          </div>
        </dl>
      )}
    </>
  );
}
