import Link from 'next/link';

export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className='bg-red py-6'>
      <div className='flex justify-center gap-6'>
        <span className='text-center text-xs leading-5 text-charcoal'>
          &copy; {currentYear} iKhokha. All Rights Reserved.
        </span>
        <Link
          href='/terms-and-conditions'
          className='text-center text-xs leading-5 text-charcoal hover:underline'
        >
          Terms & Conditions
        </Link>
      </div>
    </footer>
  );
}
